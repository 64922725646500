<template>

    <Head>
        <title>{{ page.meta_title }}</title>
        <meta name="description" v-if="page.meta_description" :content="page.meta_description">
    </Head>

    <Header :menu_items="menu_items" :page="page" :editable="editable" :website="website"/>


    <section class="text-gray-600 body-font section-text">
        <div class="bg-white dark:bg-black dark:text-white">
            <div>
                <main class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                    <div class="flex flex-wrap px-1 pt-10 page-ckeditor-sitemap">
                        <div class="w-2/3 -m-4">

                            <div>
                                <slot name="page_left">
                                </slot>
                            </div>

                            <h2 class="mt-3 m-4">Pagina's</h2>
                            <ul>
                                <li v-for="pagina in menu_items.sitemap">
                                    <jet-nav-link :href="'/'+pagina.url" class="dark:text-gray-200">{{ pagina.name }}</jet-nav-link>
                                </li>
                            </ul>

                            <h2 class="mt-3  m-4">Categorieën</h2>
                            <ul>
                                <li v-for="category in website.categories">
                                    <jet-nav-link :href="'/'+category.url" class="dark:text-gray-200">{{ category.name }}</jet-nav-link>
                                    <ul >
                                        <li v-for="sub_category in category.sub_categories">
                                            <jet-nav-link :href="'/'+sub_category.url" class="dark:text-gray-200">{{ sub_category.name }}</jet-nav-link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <div class="clear-both h-20"></div>
                        </div>
                        <div class="w-1/3">
                            <div class="  text-left rounded border shadow-lg p-3  leading-7 bg-indigo-600 text-white heading-7">
                                <slot name="page_right_orange">
                                </slot>
                            </div>
                            <slot name="page_right">
                            </slot>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </section>


    <Footer :menu_items="menu_items" :page="page" :website="website">
        <template #content_website_footer_left>
            <slot name="content_website_footer_left">
            </slot>
        </template>
        <template #content_website_footer_center>
            <slot name="content_website_footer_center">
            </slot>
        </template>
        <template #content_website_footer_right>
            <slot name="content_website_footer_right">
            </slot>
        </template>
    </Footer>
</template>


<script>

import Header from '@/Pages/Admin/Page/Templates/Partials/Header.vue';
import Footer from '@/Pages/Admin/Page/Templates/Partials/Footer.vue';
import JetNavLink from "@/Jetstream/NavLink.vue";
import {Head} from "@inertiajs/vue3";

export default {

    components: {
        JetNavLink,
        Header,
        Footer,
        editable: Boolean,
        Head
    },
    props: {
        menu_items: Object,
        page: Object,
        editable: Boolean,
        website: Object,
    },

    data() {
        return {}
    },
    methods: {
        itemsContains(n) {
            return this.page.page_options.indexOf(n) > -1
        }
    }
}
</script>

